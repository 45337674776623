import React from 'react'
import { graphql } from 'gatsby'

import Img from 'gatsby-image'

import Layout from 'components/Layout'
import Footer from 'components/Footer'
import Header from 'components/Header'

export default ({ data }) => {
  const headerSources = [
    data.smallHeader.childImageSharp.fluid,
    {
      ...data.header.childImageSharp.fluid,
      media: '(min-width: 600px)',
    },
  ]

  return (
    <Layout pageTitle='Om oss' siteImage={data.header}>
      <Header fluidImage={headerSources} />
      <oma-grid-row>
        <section className='section'>
          <h1 className='heading page__heading'>Om oss</h1>
          <p>
            Stordjursveterinärerna i Varberg är en ambulerande veterinärpraktik.
            Vi utgår från vår veterinärstation i Gödestad mitt i Varbergs
            kommun.
          </p>
          <p>
            Vi arbetar med lantbruksdjur och häst i fält och i viss mån kan vi
            ta emot din hund eller katt på vår veterinärstation.
          </p>
          <p>Välkommen att kontakta oss för mer information!</p>
          <h2>Vårt arbetslag</h2>
          <p>
            Vi som arbetar på Stordjursveterinärerna i Varberg strävar efter att
            ge dig och din besättning eller ditt djur bästa möjliga
            veterinärservice. Vi delar alla det personliga engagemanget för våra
            djur och djurägare. Vi erbjuder bred kompetens, hög kvalitet och
            konkurrenskraftiga priser. Vi vill att du som djurägare ska känna
            dig trygg hos oss.
          </p>
        </section>
      </oma-grid-row>

      <oma-grid-row fullWidth class='full_width_image__row--profile'>
        <Img
          className='full_width_image'
          imgStyle={{ objectPosition: '50% 10%' }}
          fluid={data.lars.childImageSharp.fluid}
        />
      </oma-grid-row>
      <oma-grid-row class='row--profile'>
        <section className='section'>
          <h2>Lars Olhav Olsson</h2>
          <hr />
          <h3>Legitimerad veterinär</h3>
          <p>
            Lars blev legitimerad veterinär vid SLU Uppsala 2004 och har sedan
            dess arbetat i Halland med lantbruksdjur och häst. Han är delägare
            och grundare av Stordjursveterinärerna i Varberg som startades 2014.
            Lars har vidareutbildning inom Hälsopaket mjölk, villkorad
            läkemedelsanvändning mjölkkobesättningar, seminveterinär för nöt,
            Smittsäkrad besättning nöt och ritningsgranskning inför förprövning.
            Han arbetar även några timmar per vecka åt Livsmedelsverket som
            besiktningsveterinär på Öströö Fårfarm.
          </p>
        </section>
      </oma-grid-row>

      <oma-grid-row fullWidth class='full_width_image__row--profile'>
        <Img
          className='full_width_image'
          imgStyle={{ objectPosition: '50% 10%' }}
          fluid={data.annamaria.childImageSharp.fluid}
        />
      </oma-grid-row>
      <oma-grid-row class='row--profile'>
        <section className='section'>
          <h2>AnnaMaria Olhav Olsson</h2>
          <hr />
          <h3>Legitimerad veterinär</h3>
          <p>
            AnnaMaria blev legitimerad veterinär vid SLU i Uppsala 2010 och har
            sedan dess arbetat i Halland med lantbruksdjur och häst. Hon är
            delägare och grundare av Stordjursveterinärerna i Varberg som
            startades 2014. AnnaMaria har vidareutbildning inom Hälsopaket
            mjölk, villkorad läkemedelsanvändning mjölkkobesättningar,
            Smittsäkrad besättning gris, fårhälsovård och grishälsovård,
            seminveterinär för häst, vårdhygien, officiell veterinär för alla
            djurslag och hon är godkänd att utföra ID- och
            signalementsbeskrivning på häst.
          </p>
        </section>
      </oma-grid-row>

      <oma-grid-row fullWidth class='full_width_image__row--profile'>
        <Img
          className='full_width_image'
          imgStyle={{ objectPosition: '50% 10%' }}
          fluid={data.annajennie.childImageSharp.fluid}
        />
      </oma-grid-row>
      <oma-grid-row class='row--profile'>
        <section className='section'>
          <h2>Anna-Jennie Lyngsmark</h2>
          <hr />
          <h3>Legitimerad veterinär</h3>
          <p>
            Anna-Jennie blev legitimerad veterinär vid SLU i Uppsala 2012 och
            har sedan dess arbetat i Halland med lantbrukets djur och häst. Hon
            är delägare och grundare av Stordjursveterinärerna i Varberg som
            startades 2014. Anna-Jennie har tidigare erfarenhet av att arbeta
            som sköterska på smådjursklinik. Hon har vidareutbildning inom
            Hälsopaket mjölk, villkorad läkemedelsanvändning
            mjölkkobesättningar, officiell veterinär för alla djurslag,
            hästtandvård steg 1 och hon är godkänd att utföra ID- och
            signalementsbeskrivning på häst.
          </p>
        </section>
      </oma-grid-row>

      <oma-grid-row fullWidth class='full_width_image__row--profile'>
        <Img
          className='full_width_image'
          imgStyle={{ objectPosition: '50% 10%' }}
          fluid={data.thomas.childImageSharp.fluid}
        />
      </oma-grid-row>
      <oma-grid-row class='row--profile'>
        <section className='section'>
          <h2>Thomas Englund</h2>
          <hr />
          <h3>Legitimerad veterinär</h3>
          <p>
            Thomas blev legitimerad veterinär vid SLU i Uppsala 2018 och har
            tidigare jobbat som djursjukvårdare och avdelningschef för
            smådjurskliniken på Universitetsdjursjukhuset i Uppsala. Thomas har
            arbetat som veterinär med både lantbruksdjur och sällskapsdjur innan
            han kom till Stordjursveterinärerna 2019. Han är vidareutbildad inom
            genetik och avelsstrategi och har ett speciellt intresse för får och
            fjäderfä.
          </p>
        </section>
      </oma-grid-row>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    smallHeader: file(relativePath: { eq: "duo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 599, maxHeight: 599, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    header: file(relativePath: { eq: "duo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    thomas: file(relativePath: { eq: "thomas.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 520, cropFocus: NORTH, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    lars: file(relativePath: { eq: "lars.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 520, cropFocus: NORTH, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    annamaria: file(relativePath: { eq: "annamaria.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 520, cropFocus: NORTH, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    annajennie: file(relativePath: { eq: "anna-jennie.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 520, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
